<template>
   <el-col class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>分析</el-breadcrumb-item>
            <el-breadcrumb-item>活跃度分析</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
          <!--  -->
         <el-row class="search-box title">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>选择日期：</label>
                  <el-date-picker
                     align="center"
                     class="m-right-10 status-time"
                     value-format="yyyy-MM-dd"
                     v-model="dateRange"
                     type="daterange"
                     :start-placeholder="$t('msg.start_date')"
                     :range-separator="$t('msg.to')"
                     :end-placeholder="$t('msg.end_date')"
                     @change="getDateRange"></el-date-picker>
               </el-row>
               <!-- <el-row class="search-item">
                  <label>手机号：</label>
                  <el-input class="width-220"  v-model="mobile" placeholder="手机号" clearable></el-input>
               </el-row> -->
            </el-row>
         </el-row>
         <el-row class="active">
            <el-row class="member">
               <el-row class="images"></el-row>
               <el-col class="content">
                  <dl>
                     <dt>活跃会会员数</dt>
                     <dd>{{ activeMemberCount }}</dd>
                  </dl>
               </el-col>
            </el-row>
            <el-row class="regularcustomer">
               <el-row class="images"></el-row>
               <el-col class="content">
                  <dl>
                     <dt>活跃老客户数</dt>
                     <dd>{{ activeOldMemberCount }}</dd>
                  </dl>
               </el-col>
            </el-row>
            <el-row class="usernumber">
               <el-row class="images"></el-row>
               <el-col class="content">
                  <dl>
                     <dt>活跃用户数</dt>
                     <dd>{{ activeUserCount }}</dd>
                  </dl>
               </el-col>
            </el-row>
         </el-row>

         <el-row class="secondarymarketing">
            <el-row class="funnel">
               <el-row class="title">活跃用户单次访问时长</el-row>
               <el-row class="row-data-box">
                  <el-col :span="12">过去7日，按日，活跃用户数</el-col>
                  <el-col :span="12">
                     <el-date-picker
                           class="m-left-10"
                           v-model="statisticsDay1"
                           @change="statisticVisitDuration"
                           align="right"
                           type="date"
                           placeholder="选择日期"
                           value-format="yyyy-MM-dd">
                     </el-date-picker>
                  </el-col>
               </el-row>
               <el-row id="user-visit-line" class="echa-box"></el-row>
            </el-row>
            <el-row class="funnel">
               <el-row class="title">活跃用户人均访问次数</el-row>
               <el-row class="row-data-box">
                  <el-col :span="12">过去7日，按日，活跃用户数</el-col>
                  <el-col :span="12">
                     <el-date-picker
                           class="m-left-10"
                           v-model="statisticsDay2"
                           @change="statisticUserVisit"
                           align="right"
                           type="date"
                           placeholder="选择日期"
                           value-format="yyyy-MM-dd">
                     </el-date-picker>
                  </el-col>
               </el-row>
               <el-table :data="userVisitData" style="margin-top: 20px;height: 280px;overflow-y: auto; width: 100%;">
                  <el-table-column prop="statisticsDay" label="日期"></el-table-column>
                  <el-table-column prop="avgVisitNumber" label="人均访问次数"></el-table-column>
               </el-table>
            </el-row>
         </el-row>

         <el-row class="reten-full">
            <el-row class="title">线上用户次日留存率</el-row>
            <el-row class="row-data-box">
               <el-col :span="12">过去7日，次日留存</el-col>
               <el-col :span="12">
                  <date-packer :datePacker="orderDateValas" @setDatePacker="getOrderDatePackeras" class="m-left-10"></date-packer>
               </el-col>
            </el-row>
            <el-table :data="tableDatas" style="margin-top: 20px;height: 280px;overflow-y: auto; width: 100%;">
               <el-table-column prop="cluster" label="分群"></el-table-column>
               <el-table-column prop="usernumber" label="初始行为用户数"></el-table-column>
               <el-table-column prop="onthatday" label="当日"></el-table-column>
            </el-table>
         </el-row>
      </el-row>
   </el-col>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { mapState } from 'vuex'
   import  {dateFactory} from "@/common/js/common";
   export default {
      computed: {
         ...mapState(['hotelInfo'])
      },
      data(){
         return {
            tableDatas:[
               {
                  cluster:'颜阳',
                  usernumber:'1',
                  onthatday:'2021-03-04'
               }
            ],
            hotelId: '',
            orderDateValas:[],
            dateRange: [],         // 活跃时间区间
            activeMemberCount: 0,    // 活跃会员数
            activeOldMemberCount: 0, // 活跃老会员数
            activeUserCount: 0,      // 活跃用户数
            statisticsDay1: '',       // 单次访问日期
            statisticsDay2: '',       // 人均访问日期
            userVisitData: [],        // 人均访问列表
            lineOption : {
               xAxis: {
                  type: 'category',
                  data: []
               },
               yAxis: {
                  type: 'value'
               },
               series: [{
                  data: [],
                  type: 'line',
                  smooth: true,
                  itemStyle:{
                     normal: {
                        color: "#1c7fff", //折线点的颜色
                        lineStyle: {
                           color: "#1c7fff" //折线的颜色
                        }
                     },
                  }
               }]
            },
            orderBeginTime:'',
            orderEndTime:'',
         }
      },
      mounted(){
        this.hotelId = this.hotelInfo.id
        // let today = moment().format('YYYY-MM-DD') // 今天
         // let firstDay = moment().startOf('month').format("YYYY-MM-DD") // 当月1号

         this.statisticsDay1 = this.statisticsDay2 =  dateFactory.getDistanceToday(0,false)
         let today = dateFactory.getDistanceToday(0,false)
         let date = new Date();
         let nowYear = date.getFullYear();
         let nowMonth = date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
         let monthDaynum = new Date(nowYear, nowMonth, 0).getDate() //本月天数
         this.dateRange = [nowYear + '-' + nowMonth + '-' + "01",today]
         this.orderDateValas = [dateFactory.getWeekDate('last'),dateFactory.dateFormat()]
         this.statisticActiveUser()
         this.statisticVisitDuration()
         this.statisticUserVisit()
      },
      methods:{
         // 获取当前活跃度时间段
         getDateRange(val){
            this.dateRange = val
            this.statisticActiveUser()
         },
          // 工单统计时间段过滤
          getOrderDatePackeras(val) {
            this.orderDateVal = val;
            if (this.orderDateVal) {
              this.orderBeginTime = this.orderDateVal[0];
              this.orderEndTime = this.orderDateVal[1];
            } else {
              this.orderBeginTime = "";
              this.orderEndTime = "";
            }
            this.userdistribution()
          },
         // 统计用户活跃度
         statisticActiveUser(){
            const url = urlObj.activeUser
            const param = {
               hotelId: this.hotelInfo.id,
               beginTime: this.dateRange[0],
               endTime: this.dateRange[1]
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let data = res.records
                  this.activeMemberCount = data.activeMemberCount || 0
                  this.activeOldMemberCount = data.activeOldMemberCount || 0
                  this.activeUserCount = data.activeUserCount || 0
               }
            })
         },
         // 统计单次访问时长 wenting getActiveUserVisitDuration
         statisticVisitDuration(){
            const url = urlObj.visitDuration
            const param = {
               hotelId: this.hotelInfo.id,
               date: 'WEEK',
               statisticsDay: this.statisticsDay1,
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let data = res.records.marketingDTOList
                  data.forEach(item => {
                     this.lineOption.xAxis.data.push(item.statisticsDay)
                     this.lineOption.series[0].data.push(item.avgVisitDuration)
                  })
                  this.$echarts.init(document.getElementById('user-visit-line')).setOption(this.lineOption)
               }
            })
         },
         // 统计人均访问次数 wenting getActiveUserVisitNumber
         statisticUserVisit(){
            const url = urlObj.userVisitMum
            const param = {
               hotelId: this.hotelId,
               date: 'WEEK',
               statisticsDay: this.statisticsDay2,
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.userVisitData = res.records.marketingDTOList
               }
            })
         }
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.hotelId = this.hotelInfo.id
                  this.statisticActiveUser()
                  this.statisticVisitDuration()
                  this.statisticUserVisit()
               }
            },
            deep: true
         }
      },
   }
</script>

<style scoped  lang="scss">
.secondarymarketing{
   display: flex;
   height: auto;
   margin:20px;
   .funnel{
      width: 49%;
      background-color: white;
      box-shadow: 0px 0px 20px #dce7f9;
      .title{
         height: 50px;
         line-height: 50px;
         background: #f7f7f8;
         padding: 0px 20px;
      }
      .row-data-box{
         height: 50px;
         line-height: 50px;
         padding: 0px 20px;
      }
      .echa-box{
         padding: 0px 20px;
         height: 280px;
      }
   }
   .funnel:last-child{
      margin-left: 2%;
   }
}

.reten-full{
   margin: 0px 20px;
   background-color: white;
   box-shadow: 0px 0px 20px #dce7f9;
   .title{
      height: 50px;
      line-height: 50px;
      background: #f7f7f8;
      padding: 0px 20px;
   }
   .row-data-box{
      height: 50px;
      line-height: 50px;
      padding: 0px 20px;
   }
}





   #user-visit-line{
      width: 90%;
      height: 300px
   }
   .optiondate{float: left;}

   .active{width: 98%; height: 200px; margin: auto; display: flex; box-shadow: 0px 0px 20px #dce7f9; padding: 1rem}
   .active .el-row{width: 32%; height: 100%; margin-left: 2%; border-radius: 1%;}
   .active .member{background: #0272ff; margin-left: 0px; display: flex; width: 32%; height: 100%; border-radius: 1%;}
   .member .images{width: 100px; height: 100px; border: 1px solid white; border-radius: 50%; margin-left: 10%; margin-top: 7%; background: url("../../../assets/lALPBF8a8Tu4p_JkzQEs_300_100.png") 0px 0px no-repeat; }
   .active .regularcustomer{background: #4ad7b7; width: 32%; height: 100%; margin-left: 2%; border-radius: 1%; display: flex;}
   .regularcustomer .images{width: 100px; height: 100px; border: 1px solid white; border-radius: 50%; margin-left: 10%; margin-top: 7%; background: url("../../../assets/lALPBF8a8Tu4p_JkzQEs_300_100.png") -99px 0px no-repeat;}
   .active .usernumber{background: #ea4b33; width: 32%; height: 100%; margin-left: 2%; border-radius: 1%; display: flex}
   .usernumber .images{width: 100px; height: 100px; border: 1px solid white; border-radius: 50%; margin-left: 10%; margin-top: 7%; background: url("../../../assets/lALPBF8a8Tu4p_JkzQEs_300_100.png") -200px 0px no-repeat;}
   .content{width: 50%;}
   .content dl{margin-top: 13%;margin-left: 20px; line-height: 50px;}
   .content dl dt{font-size: 18px; color: white;}
   .content dl dd{font-size: 28px; font-weight: bold; color: white;}

   .numberofactiveusers{width: 98%; margin: auto; margin-top: 2rem; height: 400px; padding-bottom: 1rem;}
   .accesstime{width: 49%; height: 100%; box-shadow: 0px 0px 20px #dce7f9; float: left;background-color: white;}
   .visittimes{width: 49%; height: 100%; box-shadow: 0px 0px 20px #dce7f9; float:right;background-color: white;}
   .visittimes .table{ overflow-y: auto; height: 280px }

   //.titles{width: 100%; height: 60px; line-height: 60px; padding-left: 1rem;}
   .ofactiveusers{font-size: 14px; padding-left: 1rem;}
   /deep/ .has-gutter tr th{text-align: center;}
   /deep/ tbody .el-table__row td{text-align: center}
   /deep/.el-table{margin-top: 2.6rem;}


   @media screen and (max-width: 1366px) {
     .main {
       width: 1300px;
     }
   }
</style>
